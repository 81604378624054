<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      modal-class="oderDetail"
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Close"
      header-close-content=""
      ok-only
    >
      <b-container fluid>
        <div class="text-center" v-if="isLoading">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
        <div v-else class="d-flex flex-column justify-content-between w-100 oderDetailBody">
          <div class="oderDetailContent">
            <b-card class="section-body">
              <div class="ticket-item" v-for="(ticket, index) in orderDetail" :key="index">
                <div class="ticket-detail">
                  <div class="ticket-name">{{ ticket.ticket_name }}</div>
                  <div class="product-name pr-3">{{ ticket.period_name }}</div>
                  <div class="product-name">
                    {{ ticket.game }}<span v-if="ticket.order_type === 'group'"> (Mua chung)</span>
                  </div>
                  <span class="ticket-price float-right correct"
                    >{{ formatPrice(ticket.price) }}&nbsp;<img style="position: relative; top: -2px"
                  /></span>
                  <span class="ticket-price float-right correct"
                    >{{ ticket.product + ' | ' }}&nbsp;<img style="position: relative; top: -2px"
                  /></span>
                </div>
                <div class="ticket-result d-flex flex-wrap" v-if="ticket.order_type === 'group'">
                  <div class="pl-3 font-18 font-weight-bold">Cổ phần: {{ ticket.ticket_info + '%' }}</div>
                </div>
                <div v-else-if="!ticketStatus.includes(ticket.ticket_info)" class="ticket-result d-flex flex-wrap">
                  <div
                    :class="{
                      ticketItemListNumber: true,
                      'mr-3': ticket.game.includes('Max3D') && (number_index + 1) % 3 === 0
                    }"
                    v-for="(number, number_index) in ticket.ticket_info"
                    :key="'ticket_info_' + ticket.id + '_' + number"
                  >
                    {{ number }}
                  </div>
                </div>
                <div class="ticket-result d-flex flex-wrap" v-else>
                  <div class="bgCustom p-2">
                    {{ mapTicketInfo[ticket.ticket_info] }}
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Order List</h4>
      </b-col>
      <!-- FilterUserName -->
      <b-col>
        <b-form-group id="input-name-filter" label="Account" label-for="account-filter">
          <b-form-input
            id="account-filter"
            v-model="filter_username"
            type="text"
            placeholder="Search account ..."
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- FilterName -->
      <b-col>
        <b-form-group id="input-name-filter" label="Receiver Name" label-for="name-filter">
          <b-form-input
            id="name-filter"
            v-model="filter_name"
            type="text"
            placeholder="Search receiver name ..."
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- FilterPhone -->
      <b-col>
        <b-form-group id="input-phone-filter" label="Receiver Phone" label-for="phone-filter">
          <b-form-input
            id="phone-filter"
            v-model="filter_phone"
            type="text"
            placeholder="Search receiver phone ..."
          ></b-form-input>
        </b-form-group>
      </b-col>
      <!-- FilterPayment -->
      <b-col>
        <b-form-group id="input-screen-filter" label="Payment Method" label-for="payment-filter">
          <b-form-select id="payment-filter" v-model="filter_payment_id" :options="allPaymentList">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <!-- FilterShipping -->
      <b-col>
        <b-form-group id="input-screen-filter" label="Shipping Method" label-for="shipping-filter">
          <b-form-select id="shipping-filter" v-model="filter_shipping_id" :options="allShippingList">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <!-- filter date from -->
      <b-col>
        <b-form-group id="input-from-date" label="From Date:" label-for="from-date">
          <datePicker v-model="filter_from" :config="dpOptions"></datePicker>
        </b-form-group>
      </b-col>
      <!-- filter date to -->
      <b-col>
        <b-form-group id="input-to-date" label="To Date:" label-for="to-date">
          <datePicker v-model="filter_to" :config="dpOptions"></datePicker>
        </b-form-group>
      </b-col>
      <!-- Filter Active -->
      <b-col>
        <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col style="margin-top: 25px"
        ><b-button block variant="success" @click="handleSearch">TÌM KIẾM</b-button></b-col
      >
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center orderTable"
    >
      <template v-slot:cell(product_total_price)="item">
        {{ formatPrice(item.item.product_total_price) }}
      </template>
      <template v-slot:cell(payment_fee)="item">
        {{ formatPrice(item.item.payment_fee) }}
      </template>
      <template v-slot:cell(shipping_fee)="item">
        {{ formatPrice(item.item.shipping_fee) }}
      </template>
      <template v-slot:cell(total)="item">
        {{ formatPrice(item.item.total) }}
      </template>
      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.deleted_at" variant="danger">Deleted</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Inactive</b-badge>
        <b-badge class="mr-1 mt-1" v-if="!item.item.account || item.item.mail" variant="info">Instant</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon class="text-success" @click="viewItem(item.item)">mdi-eye</v-icon>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import datePicker from 'vue-bootstrap-datetimepicker';
import axios from 'axios';
import { formatDateForView, formatDateForViewDateOfBirth } from '@/utils/time.js';

export default {
  name: 'Orders',
  components: { datePicker },
  data() {
    return {
      isLoading: true,
      search: '',
      isBusy: false,
      filter_name: '',
      filter_username: '',
      filter_phone: '',
      filter_payment_id: null,
      filter_shipping_id: null,
      filter_status: null,
      filter_from: '',
      filter_to: '',
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Account', key: 'account' },
        { text: 'Display Order Id', key: 'display_order_id' },
        { text: 'Receiver Name', key: 'receiver_name' },
        { text: 'Receiver Phone', key: 'receiver_phone' },
        { text: 'Receiver Birth', key: 'receiver_birth' },
        { text: 'Created At', key: 'created_at' },
        { text: 'Product Total Price', key: 'product_total_price' },
        { text: 'Payment Fee', key: 'payment_fee' },
        { text: 'Shipping Fee', key: 'shipping_fee' },
        { text: 'Total', key: 'total' },
        { text: 'Shipping Method', key: 'shipping' },
        { text: 'Payment Method', key: 'payment' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      allPaymentList: [],
      allShippingList: [],
      selectedPayment: null,
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        account_id: '',
        shipping_info: '',
        payment_id: '',
        status: '',
        receiver_name: '',
        receiver_phone: '',
        shipping: '',
        payment: '',
        receiver_id: '',
        receiver_birth: '',
        created_at: '',
        product_total_price: '',
        payment_fee: '',
        shipping_fee: '',
        total: '',
        display_order_id: '',
        account: ''
      },
      dialog: false,
      editedIndex: -1,
      orderDetail: [],
      ticket_info: [],
      dpOptions: {
        format: 'MM/DD/YYYY',
        sideBySide: true
      }
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {},
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      // {
      //   title: "Product",
      //   route: "product-list"
      // },
      { title: 'Order List' }
    ]);
  },
  methods: {
    handleSearch() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }

      this.timeOut = setTimeout(() => {
        this.fetchData();
      }, 400);
    },
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const adsProduct = ApiService.get(
        'orders',
        `?receiver_name=${this.filter_name}&username=${this.filter_username}&receiver_phone=${
          this.filter_phone
        }&payment_id=${this.filter_payment_id || ''}&shipping_id=${this.filter_shipping_id || ''}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&from=${this.filter_from || ''}&to=${this.filter_to || ''}&limit=${limit}&offset=${offset}`
      );
      const allGame = ApiService.get('allpayment');
      const allShipping = ApiService.get('allshipping');
      axios
        .all([adsProduct, allGame, allShipping])
        .then(
          axios.spread((...response) => {
            const adsProductRes = response[0];
            const allPaymentRes = response[1];
            const allShippingRes = response[2];
            this.items = adsProductRes.data.data.orderList.map((ads) => {
              return {
                ...ads,
                created_at: formatDateForView(new Date(ads.created_at).toISOString()),
                receiver_birth: formatDateForViewDateOfBirth(ads.receiver_birth)
              };
            });
            this.rows = adsProductRes.data.data.total;
            this.allPaymentList = allPaymentRes.data.data;
            this.allShippingList = allShippingRes.data.data;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    viewItem: function (item) {
      this.dialog = true;
      this.editedIndex = this.items.indexOf(item);
      this.modalTitle = `Order Detail #${item.display_order_id}`;
      this.editedItem = {
        ...item
      };
      this.filter_status = item.status;
      this.selectedPayment = item.payment;
      this.isLoading = true;
      ApiService.get('orders', `${this.editedItem.id}`)
        .then((resp) => {
          if (resp.status === 200) {
            this.orderDetail = resp.data.data.map((order) => {
              let { game, id, period_name, price, product, status, ticket_info, ticket_name, order_type } = order;
              ticket_info = order.ticket_info;
              this.isLoading = false;
              return {
                game,
                id,
                period_name,
                price,
                product,
                status,
                ticket_info,
                ticket_name,
                order_type
              };
            });
          }
        })
        .catch((err) => {
          this.$bvToast.toast(`${err}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },

    close() {
      this.dialog = false;
      // this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem = {
        id: '',
        account_id: '',
        shipping_info: '',
        payment_id: '',
        status: '',
        receiver_name: '',
        receiver_phone: '',
        shipping: '',
        payment: '',
        receiver_id: '',
        receiver_birth: '',
        created_at: '',
        product_total_price: '',
        payment_fee: '',
        shipping_fee: '',
        total: '',
        display_order_id: '',
        account: ''
      };
      this.editedIndex = -1;
      this.selectedPayment = null;
      this.filter_status = null;
    }
  }
};
</script>

<style lang="scss">
.oderDetail {
  .oderDetailBody {
    .oderDetailContent {
      .card {
        border: unset !important;
      }
    }
  }
}
.bgCustom {
  background-color: #00509d;
  color: #fff;
  font-weight: bold;
}
</style>
